// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'test',
  production: false,
  maintenanceMode: false,
  features: [], //'procore'
  url: 'https://test.hidoefacilities.org',
  apiUrl: 'https://api.test.hidoefacilities.org',
  cognitoDomain: 'cpt-userpool-test.auth.us-west-2.amazoncognito.com',
  cognitoUserPoolId: 'us-west-2_Yn5ReVeaF',
  cognitoClientId: '7pje6d6r1t6tase5d9kufhcvg9',
  auth0TenantId: 'dev-58cds8tb4yeta8pm',
  auth0ClientId: '4DiQrormJKlRDj7zX5PQ6ulLtn2U06Wc',
  procoreUrl: 'https://sandbox.procore.com/4063550/company/home/list',
  procoreAuthUrl: 'https://login-sandbox.procore.com/oauth',
  procoreApiUrl: 'https://sandbox.procore.com/rest/v1.0',
  procoreClientId: '8b8a94db845ae96bf6e7a95bbaaaf5cb8219a51308b63083a32f0210eab9d077',
  procoreClientSecret: 'de503736f36cb02e8706c19a328c6c0065e4da00728cb7e2388b9b8d741c09cd',
  cpt1Domain: 'hidoetest.datahouse.com',
  cpt1Logout: 'https://c0aci626.caspio.com/folderlogout',
  ssoEnabled: true,
  clarityProjectId: 'gk3btg03ic',
  dynatraceSrc: 'https://js-cdn.dynatrace.com/jstag/147f273fa2a/bf08103qjn/c9c45ea263c7f197_complete.js',
  portalUrl: 'https://hidoetest.datahouse.com/ ',
  quicksightEmbedUrl: '',
  quicksightLoginUrl: '',
  doeFacilitiesPortal: 'https://hidoetest.datahouse.com',
  cspUrl: 'https://test.csp.hidoefacilities.org',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
